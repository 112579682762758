<template>
  <div>test</div>
</template>

<script>
import _ from "lodash";
import data from "../assets/json/ghtk_raw.json";

export default {
  data() {
    return {
      address: data,
      result: []
    };
  },
  mounted() {
    const result = [];
    while (this.address.length > 0) {
      // eslint-disable-next-line
      let [districts, rest] = _.partition(this.address, (o) => {
        return o.province_id === this.address[0].province_id;
      });
      const province = {
        areaJx: this.address[0].province_name,
        childNodes: [],
        nameBig: this.address[0].province_name,
        name: this.address[0].province_name,
        outlets: this.address[0].province_id,
        id: this.address[0].province_id,
        type: "1",
        shorthandName: this.address[0].province_name
      };

      while (districts.length > 0) {
        const [wards, otherWards] = _.partition(districts, (o) => {
          return o.district_id === districts[0].district_id;
        });
        const district = {
          areaJx: districts[0].district_name,
          childNodes: [],
          nameBig: districts[0].district_name,
          name: districts[0].district_name,
          outlets: districts[0].district_id,
          id: districts[0].district_id,
          type: "2",
          shorthandName: districts[0].district_name
        };
        wards.forEach((ward) => {
          district.childNodes.push({
            areaJx: ward.ward_name,
            childNodes: [],
            nameBig: ward.ward_name,
            name: ward.ward_name,
            outlets: ward.ward_id,
            id: ward.ward_id,
            type: "3",
            shorthandName: ward.ward_name
          });
        });

        province.childNodes.push(district);
        districts = otherWards;
      }

      result.push(province);
      this.address = rest;
    }
    this.result = result;
    console.log(result);
  }
};
</script>
